import React, { ComponentType, ReactNode } from 'react'
import ReactDOM from 'react-dom'

interface WrappedComponent {
  className?: string
}

type DetectContentOverflowProps = WrappedComponent
interface DetectContentOverflowState {
  hasOverflow: boolean
}

export const detectContentOverflow = <P extends WrappedComponent>(Component: React.ComponentType<P>) =>
  class DetectContentOverflow extends React.PureComponent<P & DetectContentOverflowProps, DetectContentOverflowState> {
    private rootEl

    constructor(props) {
      super(props)
      this.state = {
        hasOverflow: false,
      }
    }

    componentDidMount() {
      this.rootEl = ReactDOM.findDOMNode(this)
      window.addEventListener('resize', this.checkHasOverflow)
    }

    componentDidUpdate() {
      this.checkHasOverflow()
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.checkHasOverflow)
    }

    checkHasOverflow = () => {
      const hasOverflow = this.rootEl && (this.rootEl.scrollHeight > this.rootEl.clientHeight)
      this.setState({ hasOverflow })
    }

    render() {
      const { hasOverflow } = this.state
      const className = [
        this.props.className || '',
        hasOverflow ? 'has-overflow' : '',
      ].join(' ')
      return (
        <Component {...this.props} className={className} />
      )
    }
  }
