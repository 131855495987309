import { Demo } from './models/demo'
import fuzzysort from 'fuzzysort'

const SEARCH_OPTIONS = {
  allowTypo: false,
  keys: [
    'title',
    'description',
    'ownerName',
    'ownerEmail',
    'tagNames',
  ] as any,
  scoreFn: res => {
    const results = res.filter(field => field)
    if (results.length > 0) {
      const highScore = Math.max(...results.map(result => result.score))
      if (res[1] && res[1].score === highScore) {
        return highScore - 100
      }
      return highScore
    }
    return null
  }
}

export interface FilterBy {
  keywords?: string
  categories: Set<string>
  tags: Set<string>
}

export interface SortBy {
  title: string
  key: string
  comparator: (a: Demo, b: Demo) => number
}

export function sortByAlpha(a, b) {
  return a.title.toLowerCase().localeCompare(b.title.toLowerCase())
}

export function sortByRecent(a, b) {
  return b.createdAt.diff(a.createdAt)
}

export function sortByUpdated(a, b) {
  return b.updatedAt.diff(a.updatedAt)
}

export function filterDemos(demos: Demo[], filters: FilterBy) {
  const { keywords, categories, tags } = filters
  if (categories.size > 0) {
    demos = demos.filter(demo => categories.has(demo.category))
  }
  if (tags.size > 0) {
    demos = demos.filter(demo => demo.tags.some(tag => tags.has(tag.id + '')))
  }
  if (keywords && keywords.length > 1) {
    demos = fuzzysort.go(keywords, demos, SEARCH_OPTIONS)
      .map(result => result.obj)
  }
  return demos
}

export const sortingTypes: SortBy[] = [
  {
    title: 'Alphabetically (A-Z)',
    key: 'A-Z',
    comparator: sortByAlpha,
  },
  {
    title: 'Last Added',
    key: 'recent',
    comparator: sortByRecent,
  },
  {
    title: 'Last Updated',
    key: 'recentUpdated',
    comparator: sortByUpdated,
  },
  {
    title: 'Most Popular',
    key: 'popular',
    comparator: (a, b) => b.avgRating - a.avgRating,
  },
]
