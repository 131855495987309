import * as React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Demo } from '@data'
import { MarkdownContent } from '@components/MarkdownContent'

import './DemoListItem.scss'

interface Props {
  demo: Demo
  showDescription?: boolean
  onClick?: (demo: Demo) => void
}

export class DemoListItem extends React.Component<Props, any> {
  renderAvatar() {
    const { title, thumbnail } = this.props.demo
    const demoAbbrv = title.slice(0, 2)
    if (!thumbnail) {
      return (
        <abbr
          className="demo-list-item__img_placeholder slds-avatar__initials slds-icon-standard-account"
          title={`"${title}" placeholder thumbnail`}
        >
          {demoAbbrv}
        </abbr>
      )
    } else {
      return (<img
        className="demo-list-item__img"
        src={thumbnail}
        alt={`"${title}" thumbnail`}
        title={`"${title}" thumbnail`}
      />)
    }
  }

  formatDate(date: moment.Moment) {
    if (moment().diff(date, 'days') > 7) {
      return date.format('LL')
    } else {
      return date.fromNow()
    }
  }

  onClick = e => {
    if (this.props.onClick) {
      this.props.onClick(this.props.demo)
    }
  }

  render() {
    const { slug, title, ownerName, description, createdAt, updatedAt, ownerEmail } = this.props.demo
    const showDescription = this.props.showDescription == null ? true : this.props.showDescription
    return (
      <div className="demo-list-item slds-media" onClick={this.onClick}>
        <div className="slds-media__figure">
          <Link to={`/demo/${slug}`}>
            <span className="demo-list-item__avatar slds-avatar slds-avatar_large">
                {this.renderAvatar()}
            </span>
          </Link>
        </div>
        <div className="slds-media__body">
          <article className="slds-tile">
            <h3
              className="slds-tile__title slds-truncate"
              title={title}
            >
              <Link to={`/demo/${slug}`}>
                {title}
              </Link>
            </h3>
            {showDescription && <div className="demo-list-item__description">
              <div className="slds-m-vertical_xx-small">
                <MarkdownContent className="markdown-reset" source={description.split(/\n/g)[0]} />
              </div>
            </div>}
            <div className="slds-tile__detail">
              <ul className="demo-list-item__byline slds-list_horizontal slds-has-dividers_right">
                {ownerName && <li className="slds-item">
                  <span>{ownerName}</span>
                </li>}
                <li className="slds-item demo-list-item__updated">updated {this.formatDate(updatedAt)}</li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    )
  }
}
