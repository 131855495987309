import * as React from 'react'
import { SFDCIcon } from '@components/SFDCIcon'
import { RouterLinkButton } from '@components/RouterLinkButton'

import './Button.scss'

interface Props {
  type: string
  icon: string
  onClick?: (_: void) => any
  href?: string
  textStyle?: string
  fontSize?: 'small' | 'x-small' | 'large'
  text?: string
  className?: string
  children?: any
  onMouseOver?: (e) => any
  onMouseOut?: (e) => any

  // Button modifiers
  border?: boolean
  'border-filled'?: boolean
  'container-more'?: boolean
  brand?: boolean
  selected?: boolean
}

export class Button extends React.Component<Props, any> {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  get className() {
    const optionalClasses = ['border', 'border-filled', 'container-more', 'brand']
      .filter(modifier => this.props[modifier])
      .map(modifier => `slds-button_icon-${modifier}`)

    return [
      'slds-button',
      'slds-button_icon',
      this.props.selected ? 'slds-is-selected' : '',
      this.props.text != null ? 'slds-button_has-text' : '',
      this.props.className || '',
      ...optionalClasses,
    ].join(' ')
  }

  render() {
    const { icon, type, text, textStyle, fontSize, href,
      onMouseOver, onMouseOut } = this.props

    const iconClass = fontSize ? `slds-button__icon_${fontSize}` : ''

    const buttonProps: any = {
      className: this.className,
      onClick: this.onClick,
      ...(onMouseOver && { onMouseOver }),
      ...(onMouseOut && { onMouseOut }),
    }

    let ButtonTag
    if (href) {
      if (href.startsWith('/')) {
        ButtonTag = RouterLinkButton
        buttonProps.to = href
      } else {
        ButtonTag = 'a'
        buttonProps.href = href
      }
    } else {
      ButtonTag = 'button'
    }

    return (
      <ButtonTag {...buttonProps}>
        <SFDCIcon button type={type} name={icon} textStyle={textStyle} className={iconClass} />
        {this.props['container-more']
          && <SFDCIcon button type="utility" name="down" textStyle={textStyle} className="slds-button__icon_small" />}
        {text == null && <span className="slds-assistive-text">{icon}</span>}
        {text != null && <span className="slds-button__text">{text}</span>}
      </ButtonTag>
    )
  }
}
