import * as React from 'react'
import { SFDCIcon } from '@components/SFDCIcon'
import { debounce } from '@utils'

interface Props {
  defaultValue?: string
  debounceMs?: number
  onChange: (text: string) => void
}

export class SearchField extends React.Component<Props, any> {
  private input: React.RefObject<HTMLInputElement> = React.createRef()

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.clearValue = this.clearValue.bind(this)
    this.notifyOnChange = debounce(this.notifyOnChange.bind(this), props.debounceMs || 300)
  }

  clearValue() {
    this.input.current.value = ''
    this.props.onChange('')
  }

  notifyOnChange(text: string) {
    this.props.onChange(text)
  }

  onChange(e) {
    e.persist()
    this.notifyOnChange(e.target.value)
  }

  render() {
    const { defaultValue } = this.props
    return (
      <div className="search-field slds-form-element">
        <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right">
          <SFDCIcon name="search" type="utility" ariaHidden className="slds-input__icon slds-input__icon_left" />
          <input
            ref={this.input}
            id="search-input"
            placeholder="Keyword Search"
            className="slds-input"
            type="text"
            defaultValue={defaultValue || ''}
            onChange={this.onChange}
          />
          <button onClick={this.clearValue} className="slds-button slds-button_icon slds-input__icon slds-input__icon_right" title="Clear">
            <SFDCIcon
              button
              name="clear"
              type="utility"
              textStyle="light"
              ariaHidden
            />
            <span className="slds-assistive-text">Clear</span>
          </button>
        </div>
      </div>
    )
  }
}
