/* tslint:disable:jsx-no-lambda */

import * as React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router'
import { inject, observer, Observer } from 'mobx-react'
import { DemoDetail, DemoDetailNotFound, DemoList } from '@views/demo'
import { getDemoBySlug, DataService, PaginationState } from '@data'
import { AppDataContext } from '@data/contexts'
import { GAAnalytics } from '@utils'
import { AppConfig } from '@config'

import { createBrowserHistory } from 'history'

type FilteringContext = 'default' | 'search'

/* Routes JSX */

const DemoDetailRoute = inject('store')(observer(props => {
  const { params } = props.match
  const demos = props.store.demos || []
  const demo = demos.find(demo => demo.slug === params.id)
  if (demo) {
    document.title = `${AppConfig.PAGE_TITLE} - ${demo.title}`
    return <DemoDetail demo={demo}/>
  }
  document.title = `${AppConfig.PAGE_TITLE} - Not Found`
  return <DemoDetailNotFound />
}))

export const AppRoutes = withRouter(
inject('store')(
observer(
class AppRoutes extends React.Component<any, any> {
  private filteringContext: FilteringContext = 'default'

  constructor(props) {
    super(props)
  }

  get demos() {
    return (this.props.store || {}).demos || []
  }

  setFilteringContext(nextContext: FilteringContext) {
    const prevContext = this.filteringContext
    if (prevContext === 'search' || nextContext === 'search') {
      this.props.store.resetFilters()
    }
    this.filteringContext = nextContext
  }

  renderDemoList = props => {
    const { params } = props.match
    this.setFilteringContext('default')
    this.resetPageTitle()
    return <DemoList
      currentPage={+(params.page || 1)}
    />
  }

  renderSearchPage = props => {
    const { params } = props.match
    this.setFilteringContext('search')
    let pageTitle = ''
    if (params.tag) {
      this.props.store.setFilterByTag(params.tag)
      pageTitle = `Tag: ${decodeURI(params.tagName)}`
    } else {
      const category = decodeURI(params.category)
      pageTitle = category
      try {
        this.props.store.toggleCategory(category, true)
      } catch (e) {
        console.log(`Category '${category}' not found`)
        return this.renderDemoList(props)
      }
    }

    return <DemoList
      pageTitle={pageTitle}
      currentPage={+(params.page || 1)}
    />
  }

  resetPageTitle() {
    document.title = `${AppConfig.PAGE_TITLE}`
  }

  render() {
    return (
      <Switch>
        <Route
          path={`/demo/:id`}
          component={DemoDetailRoute}
        />
        <Route
          path="/demos/c/:category/:page?"
          render={this.renderSearchPage}
        />
        <Route
          path="/demos/t/:tag/:tagName/:page?"
          render={this.renderSearchPage}
        />
        <Route
          path="/demos/:page?"
          render={this.renderDemoList}
        />
        <Redirect from="/" to="/demos" />
      </Switch>
    )
  }
})))

export const history = createBrowserHistory()
history.listen((location, action) => {
  if (action === 'PUSH') {
    window.scrollTo(0, 0)
    GAAnalytics.trackPageView(location.pathname)
  }
})
