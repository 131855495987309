import * as React from 'react'
const UtilityIcons = require('@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg')

interface Props {
  key: string
  title: string
  onClick?: (_: any) => void
  selected?: boolean
  className?: string
}

export class DropdownItem extends React.Component<Props, any> {
  get rootClassName() {
    return ['slds-listbox__item', this.props.className || ''].join(' ')
  }

  render() {
    const { key, title, selected, onClick } = this.props
    return (
      <li
        role="presentation"
        className={this.rootClassName}
        onClick={onClick || (() => {})}
      >
        <div
          id={`listbox-option-${key}`}
          className={`slds-media slds-listbox__option slds-listbox__option_plain
                     slds-media_small slds-media_center ${selected ? 'slds-is-selected' : ''}`}
          role="option"
        >
          <span className="slds-media__figure">
            <svg className="slds-icon slds-icon_x-small slds-listbox__icon-selected" aria-hidden="true">
              <use href={`${UtilityIcons}#check`} />
            </svg>
          </span>
          <span className="slds-media__body">
            <span className="slds-truncate" title={title}>
              <span className="slds-assistive-text">Current Selection:</span> {title}</span>
          </span>
        </div>
      </li>
    )
  }
}
