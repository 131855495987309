import * as React from 'react'
import { RatingStar } from './RatingStar'

import './Rating.scss'

interface Props {
  className?: string
  maxRating: number
  userRating: number
  avgRating: number
  onRatingChange: (value: number) => void
}

interface State {
  currentStarHover: number
}

export class Rating extends React.Component<Props, State> {
  public static defaultProps = {
    maxRating: 5
  }

  constructor(props) {
    super(props)
    this.state = {
      currentStarHover: -1,
    }
  }

  get className() {
    const { className } = this.props
    const classes = ['rating', className || '']

    return classes.join(' ')
  }

  renderAvgRating() {
    const { avgRating } = this.props
    return (
      <div className="rating__avg slds-text-body_small">
        ({avgRating > 0 ? `avg: ${avgRating.toFixed(2)}` : 'No ratings yet'})
      </div>
    )
  }

  onMouseOut = e => {
    this.setState({
      currentStarHover: -1,
    })
  }

  onStarMouseOver = (starValue: number) => {
    this.setState({
      currentStarHover: starValue,
    })
  }

  render() {
    const { maxRating, userRating, avgRating, onRatingChange } = this.props
    const rating = userRating || avgRating
    return (
      <div className={this.className}>
        <div className="rating__stars" onMouseLeave={this.onMouseOut}>
          {[...Array(maxRating)].map((_, i) => (
            <RatingStar
              key={i + 1}
              onMouseOver={this.onStarMouseOver}
              isHovered={(i + 1) <= this.state.currentStarHover}
              fillRatio={Math.min(1, Math.max(0, rating - i))}
              value={i + 1}
              onClick={onRatingChange}
            />
          ))}
        </div>
        {userRating > 0 && this.renderAvgRating()}
      </div>
    )
  }
}
