import * as React from 'react'
import { Link } from 'react-router-dom'
import { Tag } from '@data'

import './TagList.scss'

interface Props {
  tags: Tag[]
  className?: string
}

export class TagList extends React.Component<Props, any> {

  get className() {
    const { className } = this.props
    return [
      'tag-list',
      'slds-list_horizontal',
      className || '',
    ].join(' ')
  }

  render() {
    const { tags } = this.props
    return (
      <ul className={this.className}>
        {tags.map(tag => (
          (<li
            key={tag.id}
            className="tag-list__li slds-p-around_xxx-small"
          >
            <Link to={`/demos/t/${tag.id}/${encodeURIComponent(tag.name)}`}>
              {tag.name}
            </Link>
          </li>)
        ))}
      </ul>
    )
  }
}
