import * as React from 'react'
import { Demo, SortBy, FilterBy, sortingTypes } from '@data'
import { DropdownMenu, DropdownItem } from '@views/layout'
import { Button } from '@components/Button'
import { FilterOptions } from './FilterOptions'

import './DemoFilters.scss'

interface Props {
  sortingTypes: SortBy[]
  sortBy: SortBy
  filterBy: FilterBy
  onSortByChange: (sortBy: SortBy) => void
  onFiltersChange: (filters: FilterBy) => void
}

interface State {
  showFilterOptions: boolean
}

export class DemoFilters extends React.Component<any, State> {
  constructor(props) {
    super(props)
    this.state = {
      showFilterOptions: true,
    }
    this.toggleFilterList = this.toggleFilterList.bind(this)
    this.onSortByChange = this.onSortByChange.bind(this)
  }

  toggleFilterList() {
    this.setState({ showFilterOptions: !this.state.showFilterOptions })
  }

  onSortByChange(title: string) {
    const sortBy = this.props.sortingTypes.find(type => type.title === title)
    this.props.onSortByChange(sortBy)
  }

  render() {
    const { sortingTypes, sortBy, onFiltersChange } = this.props
    const { showFilterOptions } = this.state
    return (
      <div className="demo-filters">
        <div className="demo-filters__buttons">
          <DropdownMenu
            className="demo-filters__sorting-menu"
            defaultSelected={sortBy.title}
            onChange={this.onSortByChange}
          >
            <DropdownItem title="Alphabetically (A-Z)" key="A-Z" />
            <DropdownItem title="Last Added" key="recent" />
            <DropdownItem title="Last Updated" key="recentUpdated" />
            <DropdownItem title="Most Popular" key="popular" />
          </DropdownMenu>
          <Button
            className="demo-filters__filter-button"
            icon="filterList"
            type="utility"
            fontSize="large"
            container-more
            onClick={this.toggleFilterList}
          />
        </div>
        {showFilterOptions && (
          <FilterOptions />
        )}
      </div>
    )
  }
}
