import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Box } from '@components/Box'
import { DataService } from '@data'
import { SearchField } from './SearchField'
import { CheckboxGroup, Checkbox } from '@components/Checkbox'
import { CategoryTree, FilterBy } from '@data'

@inject('store')
@observer
export class FilterOptions extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.renderCheckboxes = this.renderCheckboxes.bind(this)
    this.onKeywordChange = this.onKeywordChange.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
  }

  get selectedCategories() {
    return this.props.store.filterBy.categories
  }

  get categories() {
    return this.props.store.categories
  }

  onCheckboxChange = (category: CategoryTree, checked: boolean) => {
    this.props.store.toggleCategory(category, checked)
  }

  renderCheckboxes(category: CategoryTree) {
    const [name, children] = category
    if (!children.length) {
      return (<Checkbox
        label={name}
        id={name}
        key={name}
        checked={this.selectedCategories.has(name)}
        onChange={this.onCheckboxChange.bind(this, category)}
      />)
    } else {
      return (
        <CheckboxGroup
          label={name}
          key={name}
          onCheckboxGroupChange={this.onCheckboxChange.bind(this, category)}
          checked={this.selectedCategories.has(name)}
        >
          {children.map((child: CategoryTree) => this.renderCheckboxes(child))}
        </CheckboxGroup>
      )
    }
  }

  onKeywordChange(keywords: string) {
    this.props.store.filterBy.keywords = keywords
  }

  render() {
    const { store } = this.props
    return (
      <Box className="filter-options">
        <SearchField defaultValue={store.filterBy.keywords} onChange={this.onKeywordChange} />
        <div className="filter-options__checkboxes">
          {store.categories.map(category => this.renderCheckboxes(category))}
        </div>
      </Box>
    )
  }
}
