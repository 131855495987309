export function debounce(func, waitMs: number) {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => func(...args), waitMs)
  }
}
