import axios from 'axios'
import { observable, autorun, observe } from 'mobx'
import moment from 'moment'
import { AppConfig } from '@config'
import { Demo, Category, CategoryTree } from './models'
import { sortingTypes, SortBy } from './filters'
import { getDemosByPage } from './demo-helpers'

function mockRequest(ms: number) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms))
}

export class DataService {
  public readonly filterBy = observable({
    categories: new Set(),
    keywords: '',
    tags: new Set(),
  })
  public readonly demos = observable<Demo>([])
  public readonly categories = observable<CategoryTree>([])
  public welcomeContent: string = ''
  public categoryByName: Map<string, any> = new Map()

  constructor() {
    this.fetchDemos()
    this.fetchCategories()
  }

  public fetchDemos(pageNum?: number): Promise<Demo[]> {
    return axios.get(`${AppConfig.API_ROOT}/api/demos/`)
      .then(result => {
        const demos = result.data.map(demo => ({
          ...demo,
          title: demo.title.trim(),
          images: demo.images.map(image => ({
            url: image.url,
            thumbnailUrl: image.thumbnail_url,
          })),
          tagNames: demo.tags.map(tag => tag.name).join(', '),
          createdAt: moment(`${demo.createdAt}+00:00`),
          updatedAt: moment(`${demo.updatedAt}+00:00`),
          userRating: this.getUserRating(demo.id),
        }))
        this.demos.replace(demos)
        return this.demos
      })
  }

  public fetchCategories(): Promise<CategoryTree[]> {
    return axios.get(`${AppConfig.API_ROOT}/api/categories/`)
      .then(result => {
        const categories = []
        const categoryMap = {}
        result.data.forEach(category => {
          categoryMap[category.name] = categoryMap[category.name] || [category.name, []]
          this.categoryByName.set(category.name, {
            parent: category.parent,
            subtree: categoryMap[category.name],
          })
          if (category.parent) {
            categoryMap[category.parent] = categoryMap[category.parent] || [category.parent, []]
            categoryMap[category.parent][1].push(categoryMap[category.name])
          } else {
            categories.push(categoryMap[category.name])
          }
        })
        this.categories.replace(categories)
        return categories
      })
  }

  public clearSelectedCategories() {
    this.filterBy.categories.clear()
  }

  public setFilterByTag(tagId: string) {
    this.filterBy.tags.clear()
    this.filterBy.tags.add(tagId)
  }

  public clearFilterByTag() {
    this.filterBy.tags.clear()
  }

  public resetFilters() {
    this.filterBy.tags.clear()
    this.filterBy.categories.clear()
  }

  public toggleCategory(category: CategoryTree | string, checked: boolean) {
    let categoryTree = null
    if (typeof category === 'string') {
      categoryTree = this.categoryByName.get(category) && this.categoryByName.get(category).subtree
      if (!categoryTree) {
        throw Error(`Invalid category: ${category}`)
      }
    } else {
      categoryTree = category
    }
    const [name, subcategories ] = categoryTree
    this.syncCategoryGroup(categoryTree, checked)
  }

  public getSortBy(): SortBy {
    const key = localStorage.getItem('sort-by') || 'A-Z'
    return sortingTypes.find(type => type.key === key)
  }

  public getWelcomeContent(): Promise<string> {
    if (this.welcomeContent) {
      return Promise.resolve(this.welcomeContent)
    }
    return axios.get(`${AppConfig.API_ROOT}/api/gettingstarted/`)
      .then(result => result.data.content)
      .then(data => this.welcomeContent = data)
  }

  setSortBy(sortBy: SortBy) {
    localStorage.setItem('sort-by', sortBy.key)
  }

  rateDemo(demo: Demo, userRating: number): Promise<void> {
    return axios.post(`${AppConfig.API_ROOT}/api/demos/${demo.id}/rate/`, {
      rating: userRating,
    })
    .then(result => this.updateDemoRating(demo, userRating, result))
  }

  changeDemoRating(demo: Demo, newRating: number): Promise<void> {
    return axios.post(`${AppConfig.API_ROOT}/api/demos/${demo.id}/updaterating/`, {
      rating: newRating,
      prevRating: demo.userRating,
    })
    .then(result => this.updateDemoRating(demo, newRating, result))
  }

  getUserRating(demoId: string): number {
    return +(localStorage.getItem(`demo-${demoId}-rating`) || 0)
  }

  private syncCategoryGroup(category: CategoryTree, groupChecked: boolean) {
    const [name, subcategories ] = category
    subcategories.forEach(category => {
      this.syncCategoryGroup(category, groupChecked)
    })

    if (groupChecked) {
      this.filterBy.categories.add(name)
    } else {
      this.filterBy.categories.delete(name)
    }
  }

  private updateDemoRating = (demo: Demo, userRating: number, result) => {
    const { data } = result
    if (data.status !== 'success') {
      throw Error('Unexpected response')
    }
    const demoIndex = this.demos.indexOf(demo)
    const updatedDemo = {
      ...demo,
      avgRating: data.rating,
      userRating,
    }
    demo.avgRating = data.rating
    demo.userRating = userRating
    if (demoIndex > -1) {
      this.demos.replace([...this.demos])
    }
    this.saveToStorage(updatedDemo)
  }

  private saveToStorage(demo: Demo) {
    localStorage.setItem(`demo-${demo.id}-rating`, demo.userRating + '')
  }
}

export const dataService = new DataService()
