import { Demo } from './models/demo'

export function getDemoBySlug(demos: Demo[], slug: string) {
  return demos.find(demo => demo.slug === slug)
}

export function getDemosByPage(demos: Demo[], entriesPerPage: number, pageNum: number = 1) {
  const start = (pageNum - 1) * entriesPerPage
  return demos.slice(start, start + entriesPerPage)
}
