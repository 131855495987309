import { AppConfig } from '@config'

declare var gtag

export const EVT_CATEGORIES = {
  DEMO_CONTENT: 'DemoContent',
  SIDEBAR: 'Sidebar',
}

export const GAAnalytics = {
  trackDemoBodyLinkClick(url, demo, source = EVT_CATEGORIES.DEMO_CONTENT) {
    gtag('event', 'click', {
      event_category: EVT_CATEGORIES.DEMO_CONTENT,
      event_label: [demo.title, url].join(', '),
    })
  },

  trackPageView(pathname: string) {
    gtag('config', AppConfig.GA_TRACKING_CODE, {
      page_path: pathname,
    })
  }
}
