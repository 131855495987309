import * as React from 'react'
import { Demo } from '@data'

export class DemoDetailNotFound extends React.Component<any, any> {
  render() {
    return (
      <div className="demo-detail">
        <h2 className="slds-text-heading_medium">Not Found</h2>
      </div>
    )
  }
}
