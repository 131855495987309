import * as React from 'react'
import { detectContentOverflow } from '@components/HOCs'
import { Demo } from '@data'

const CustomIcons = require('@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg')
const StandardIcons = require('@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg')

interface Props {
  className?: string
  children?: any
  onClick?: () => void
  title: string
  icon: string
}

export const SidebarCard  = detectContentOverflow(class extends React.Component<Props, any> {
  get className() {
    return ['sidebar-card', 'slds-card', this.props.className || ''].join(' ')
  }

  renderIcon() {
    const { icon } = this.props
    const type = icon.startsWith('custom') ? 'custom' : 'standard'

    return (
      <div className="slds-media__figure">
        <span className={`slds-icon_container slds-icon-${type}-${icon}`}>
          <svg className="slds-icon slds-icon_small" aria-hidden="true">
            <use href={`${type === 'custom' ? CustomIcons : StandardIcons}#${icon}`} />
          </svg>
          <span className="slds-assistive-text">{icon}</span>
        </span>
      </div>)
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    const { title, icon, children } = this.props
    const Icon = this.renderIcon()
    return (
      <article className={this.className} onClick={this.onClick}>
        <div className="slds-card__header slds-grid">
          <header className="slds-media slds-media_center slds-has-flexi-truncate">
            {Icon}
            <div className="slds-media__body">
              <h2 className="slds-card__header-title">
                <span className="slds-card__header-link slds-truncate" title="Accounts">
                  <span>{title}</span>
                </span>
              </h2>
            </div>
          </header>
        </div>
        <div className="slds-card__body slds-card__body_inner">
          {children}
        </div>
      </article>
    )
  }
})
