import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { Provider, observer } from 'mobx-react'
import { Header, Sidebar } from '@views/layout'
import { AppRoutes, history } from './Routes'
import { DataService, dataService, Demo, CategoryTree } from '@data/index'
import { AppDataContext } from '@data/contexts'

import './App.scss'

interface State {
  welcomeContent: string,
}

const App = observer(class App extends Component<any, State> {
  constructor(props) {
    super(props)
    this.state = {
      welcomeContent: dataService.welcomeContent,
    }
  }

  componentWillMount() {
    dataService.getWelcomeContent().then(welcomeContent => {
      if (welcomeContent !== this.state.welcomeContent) {
        this.setState({ welcomeContent })
      }
    })
  }

  render() {
    const { welcomeContent } = this.state
    return (
      <Provider store={dataService}>
        <Router history={history}>
          <div className="app">
            <Header title="Tableau CRM Demo Assets"/>
            <div className="app__body slds-grid slds-gutters">
              <div
                className={'app__body-content slds-col'
                  + 'slds-size_1-of-1 slds-medium-size_2-of-3 slds-order_2 slds-medium-order_1'}
              >
                {dataService.demos.length > 0 && <AppRoutes />}
                {dataService.demos.length === 0 && <div>Loading...</div>}
              </div>
              <Sidebar
                className={'app__body-sidebar slds-m-bottom_small slds-col '
                  + 'slds-size_1-of-1 slds-order_1 slds-medium-size_1-of-3'}
                welcomeContent={welcomeContent}
                demos={[...dataService.demos]}
              />
            </div>
          </div>
        </Router>
      </Provider>
    )
  }
})

export default App
