import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import { MDLink } from './MDLink'

import './MarkdownContent.scss'

interface Props {
  className?: string
  source: string
  onLinkClick?: (href, text, title) => void
}

export class MarkdownContent extends React.Component<Props, any> {
  constructor(props) {
    super(props)
  }

  MDLinkWithBoundClickEvt = props => {
    const { onLinkClick } = this.props
    return <MDLink {...props} onClick={onLinkClick} />
  }

  get className() {
    const { className } = this.props
    return ['markdown-content', className || ''].join(' ')
  }

  get renderers() {
    return {
      link: this.props.onLinkClick ? this.MDLinkWithBoundClickEvt : MDLink,
    }
  }

  render() {
    const { source } = this.props
    return (
      <div className={this.className}>
        <ReactMarkdown
          className="markdown-textarea"
          source={source}
          renderers={this.renderers}
        />
      </div>
    )
  }
}
