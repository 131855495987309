import * as React from 'react'

export interface Props {
  className?: string
  label: string
  id: string
  onChange: (checked: boolean) => void
  checked: boolean
}

export class Checkbox extends React.Component<Props, any> {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  get className() {
    const { className } = this.props
    const classes = ['checkbox slds-form-element', className || '']

    return classes.join(' ')
  }

  onChange(e) {
    this.props.onChange(e.target.checked)
  }

  render() {
    const { label, id, children, checked, onChange } = this.props
    return (
      <div className={this.className}>
        <div className="slds-checkbox">
          <input
            type="checkbox"
            name="options"
            id={id}
            value="checkbox-3"
            checked={checked}
            onChange={this.onChange}
          />
          <label className="slds-checkbox__label" htmlFor={id}>
            <span className="slds-checkbox_faux" />
            <span className="slds-form-element__label">{label}</span>
          </label>
        </div>
      </div>
    )
  }
}
