import * as React from 'react'
import { MarkdownContent } from '@components/MarkdownContent'
import { SidebarCard } from '@views/demo/SidebarCard'
import { DemoListItem } from '@views/demo/DemoListItem'
import { Demo, sortByRecent } from '@data'
import Modal from 'react-modal'

import './Sidebar.scss'

const MAX_SIDEBAR_DEMOS = 3

interface Props {
  className: string
  demos: Demo[]
  welcomeContent: string
}

interface DemoState {
  recentDemos: Demo[]
  featuredDemos: Demo[]
}

interface State extends DemoState {
  gettingStartedModalIsOpen: boolean,
}

export class Sidebar extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      gettingStartedModalIsOpen: false,
      ...this.chooseDemosForDisplay(),
    }
  }

  get className() {
    const { className } = this.props
    return ['sidebar', className || ''].join(' ')
  }

  componentDidUpdate(prevProps) {
    const { demos } = this.props
    if (demos !== prevProps.demos) {
      this.setState(this.chooseDemosForDisplay())
    }
  }

  chooseDemosForDisplay(): DemoState {
    const { demos } = this.props
    return {
      recentDemos: [...demos].sort(sortByRecent).slice(0, MAX_SIDEBAR_DEMOS),
      featuredDemos: demos.filter(demo => demo.featured).slice(0, MAX_SIDEBAR_DEMOS),
    }
  }

  showGettingStartedModal = () => {
    this.setState({
      gettingStartedModalIsOpen: true,
    })
  }

  hideGettingStartedModal = () => {
    this.setState({
      gettingStartedModalIsOpen: false,
    })
  }

  render() {
    const { welcomeContent } = this.props
    const { recentDemos, featuredDemos, gettingStartedModalIsOpen } = this.state
    return (
      <div className={this.className}>
        <Modal
          isOpen={gettingStartedModalIsOpen}
          onRequestClose={this.hideGettingStartedModal}
        >
          <MarkdownContent source={this.props.welcomeContent} />
        </Modal>
        <SidebarCard
          className="sidebar__getting-started"
          title="Getting Started"
          icon="topic"
        >
          <MarkdownContent source={this.props.welcomeContent} />
          <button
            className="sidebar__getting-started-see-more slds-text-link"
            onClick={this.showGettingStartedModal}
          >
            See More
          </button>
        </SidebarCard>
        <SidebarCard className="sidebar__featured" title="Featured Demos" icon="custom43">
          {featuredDemos.map(demo => (
            <DemoListItem key={demo.slug} demo={demo} showDescription={false} />
          ))}
        </SidebarCard>
        <SidebarCard className="sidebar__recent" title="Recent Demos" icon="today">
          {recentDemos.map(demo => (
            <DemoListItem key={demo.slug} demo={demo} showDescription={false} />
          ))}
        </SidebarCard>
      </div>
    )
  }
}
