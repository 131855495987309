import * as React from 'react'
import { Box } from '@components/Box'

const CustomIcons = require('@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg')
const StandardIcons = require('@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg')
const UtilityIcons = require('@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg')
const ActionIcons = require('@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg')

interface Props {
  name: string
  type: 'standard' | 'custom' | 'utility' | 'action'
  size?: 'xx-small' | 'x-small' | 'small' | 'large'
  textStyle?: string
  className?: string
  withContainer?: boolean
  button?: boolean
}

export class SFDCIcon extends React.Component<any, any> {
  get containerClassName() {
    const { type, name, className } = this.props
    return ['slds-icon_container', `slds-icon-${type}-${name}`, className || ''].join(' ')
  }

  get iconClassName() {
    const { size, textStyle, button, withContainer, className } = this.props
    const classes = [button ? 'slds-button__icon' : 'slds-icon', `slds-icon-text-${textStyle || 'none'}`]
    if (size) {
      classes.push(`slds-icon_${size}`)
    }
    if (!withContainer && className) {
      classes.push(className)
    }
    return classes.join(' ')
  }

  get iconSymbols() {
    const { type } = this.props
    if (type === 'standard') {
      return StandardIcons
    }
    if (type === 'custom') {
      return CustomIcons
    }
    if (type === 'utility') {
      return UtilityIcons
    }
    if (type === 'action') {
      return ActionIcons
    }
  }

  get iconPath() {
    return `${this.iconSymbols}#${this.props.name}`
  }

  get icon() {
    const { name, ariaHidden } = this.props
    return (
      <svg className={this.iconClassName} aria-hidden="true">
        <use href={this.iconPath} />
      </svg>
    )
  }

  render() {
    const { name, withContainer } = this.props
    if (withContainer) {
      return (
        <span className={this.containerClassName}>
          {this.icon}
          <span className="slds-assistive-text">{name}</span>
        </span>
      )
    }

    return this.icon
  }
}
