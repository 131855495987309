import * as React from 'react'
import { Checkbox, Props as SingleCheckboxProps} from './Checkbox'

interface Props {
  className?: string
  children: React.ReactNode
  onCheckboxGroupChange: (groupChecked: boolean) => void
  label: string
  checked: boolean
}

type CheckboxProps = SingleCheckboxProps & Props

export class CheckboxGroup extends React.Component<Props, any> {
  constructor(props) {
    super(props)
  }

  get className() {
    const { className } = this.props
    const classes = ['checkbox-group', 'slds-form-element', className || '']

    return classes.join(' ')
  }

  toggleGroupCheckbox = (checked: boolean) => {
    this.props.onCheckboxGroupChange(checked)
  }

  render() {
    const { label, children, checked } = this.props
    return (
      <fieldset className={this.className}>
        <legend className="slds-form-element__legend slds-form-element__label">
          <Checkbox id={label} onChange={this.toggleGroupCheckbox} label={label} checked={checked} />
        </legend>
        <div className="checkbox-group__items slds-form-element__control">
          {children}
        </div>
      </fieldset>
    )
  }
}
