import * as React from 'react'

interface Props {
  children?: any
  onClick?: (href, text, title) => void
  title: string
  href: string
}

export class MDLink extends React.Component<any, any> {
  onClick = e => {
    const { children, onClick, href, title } = this.props
    if (onClick) {
      onClick(href, children, title || undefined)
    }
    e.stopPropagation()
    return false
  }

  render() {
    const { children, href } = this.props
    let attrs = {}
    if (!href.startsWith('/')) {
      attrs = {
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    }
    return <a {...attrs} onClick={this.onClick} href={href}>{children}</a>
  }
}
