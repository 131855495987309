import * as React from 'react'

interface Props {
  className?: string
  style?: any
  children: any[]
}

export class Box extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  get className() {
    const { className } = this.props
    const classes = ['slds-box', className || '']

    return classes.join(' ')
  }

  render() {
    const { icon, more, children, style } = this.props
    return (
      <div className={this.className} style={style || {}}>
        {children}
      </div>
    )
  }
}
