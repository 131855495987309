import * as React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Lightbox from 'react-images'
import { Box } from '@components/Box'
import { MarkdownContent } from '@components/MarkdownContent'
import { Rating } from '@components/Rating'
import { dataService, Demo, Tag } from '@data/index'
import { GAAnalytics } from '@utils'
import { TagList } from './TagList'

import './DemoDetail.scss'

interface Props {
  demo: Demo
}

interface State {
  lightboxIsOpen: boolean
  lightboxImageIndex: number
}

export class DemoDetail extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      lightboxIsOpen: false,
      lightboxImageIndex: 0,
    }
  }

  get className() {
    const { demo } = this.props
    return [
      'demo-detail',
      demo.images.length === 0 ? 'demo-detail_placeholder-img' : '',
    ].join(' ')
  }

  formatDate(date: moment.Moment) {
    if (moment().diff(date, 'days') > 7) {
      return date.format('LL')
    } else {
      return date.fromNow()
    }
  }

  onThumbnailClick = e => {
    const index = e.currentTarget.dataset.index
    this.setState({
      lightboxImageIndex: +index,
      lightboxIsOpen: true,
    })
  }

  onLightboxClose = () => {
    this.setState({ lightboxIsOpen: false })
  }

  onLightboxClickNext = () => {
    const lightboxImageIndex = (this.state.lightboxImageIndex + 1) % this.numImages
    this.setState({ lightboxImageIndex })
  }

  onLightboxClickPrev = () => {
    const lightboxImageIndex = (this.state.lightboxImageIndex - 1) % this.numImages
    this.setState({ lightboxImageIndex })
  }

  onRatingChange = (rating: number) => {
    const demo = this.props.demo
    if (demo.userRating === 0) {
      dataService.rateDemo(this.props.demo, rating)
    } else {
      dataService.changeDemoRating(this.props.demo, rating)
    }
  }

  trackDemoLinkClick = (url, text) => {
    GAAnalytics.trackDemoBodyLinkClick(url, this.props.demo)
  }

  get numImages() {
    return this.props.demo.images.length
  }

  renderBreadcrumbs = demo => {
    const { category } = demo
    const categories = [category]
    let parent = (dataService.categoryByName.get(category) || {}).parent
    while (parent && categories.length < 3) {
      categories.unshift(parent)
      parent = (dataService.categoryByName.get(parent) || {}).parent
    }
    return <ul className="demo-detail__breadcrumbs slds-list_horizontal slds-has-dividers_right">
      <li>
        <Link to={`/demos/`}>Home</Link>
      </li>
      {categories.map(category => (
        <li key={category}>
          <Link to={`/demos/c/${encodeURIComponent(category)}`}>{category}</Link>
        </li>
      ))}
    </ul>
  }

  render() {
    const { demo } = this.props
    return (
      <div className={this.className}>
        {this.renderBreadcrumbs(demo)}
        <Box
          className="demo-detail__box slds-m-bottom_large slds-brand-band_user"
          style={{backgroundImage: `url(${demo.mainImage || ''})`}}
        />
        <h2 className="demo-detail__title slds-text-heading_medium">{demo.title}</h2>
        <div className="demo-detail__byline">
          <ul className="slds-list_horizontal slds-has-dividers_right">
            <li className="slds-item">
              <span>
                by <a href={`mailto:${demo.ownerEmail}`}>{demo.ownerName}</a>
              </span>
            </li>
            <li className="slds-item">created {this.formatDate(demo.createdAt)}</li>
            <li className="slds-item">updated {this.formatDate(demo.updatedAt)}</li>
          </ul>
        </div>
        {demo.tags && <TagList className="slds-m-vertical_x-small" tags={demo.tags} />}

        <MarkdownContent source={demo.description} onLinkClick={this.trackDemoLinkClick} />

        {demo.images.length > 0 && <div className="demo-detail__thumbnails slds-m-vertical_large slds-p-vertical_large">
          {demo.images.map((src, i) => (
            <a key={src.url} className="demo-detail__thumbnail" data-index={i} onClick={this.onThumbnailClick}>
              <img src={src.thumbnailUrl || src.url} />
            </a>
          ))}
        </div>}

        <Lightbox
          images={demo.images.map(img => ({ src: img.url }))}
          isOpen={this.state.lightboxIsOpen}
          onClose={this.onLightboxClose}
          currentImage={this.state.lightboxImageIndex}
          onClickPrev={this.onLightboxClickPrev}
          onClickNext={this.onLightboxClickNext}
        />
        <Rating
          maxRating={5}
          userRating={demo.userRating}
          avgRating={demo.avgRating}
          onRatingChange={this.onRatingChange}
        />
      </div>
    )
  }
}
