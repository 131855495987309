import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@components/Button'

import './Header.scss'

interface Props {
  title: string
}

export class Header extends React.Component<Props, any> {
  openContactUs() {
    window.open('https://org62.lightning.force.com/lightning/r/CollaborationGroup/0F90M000000HYeXSAW/view')
  }

  render() {
    const { title } = this.props
    return (
      <header className="header slds-page-header">
        <Link to="/">
          <h1 className="slds-text-heading_large">{title}</h1>
        </Link>
        <button className="header__button slds-button slds-button_brand" onClick={this.openContactUs}>
          Contact Us
        </button>
      </header>
    )
  }
}
