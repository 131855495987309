import * as React from 'react'
import onClickOutside from 'react-onclickoutside'
const UtilityIcons = require('@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg')
import { SFDCIcon } from '@components/SFDCIcon'

import './DropdownMenu.scss'

interface Props {
  children: any
  defaultSelected?: string
  className?: string
  onChange: (value: string) => void
  small?: boolean
}

interface State {
  expanded: boolean
  value: string
}

class BaseDropdownMenu extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      value: props.defaultSelected || null
    }
    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  get className() {
    const { className, small } = this.props
    return [
      'dropdown-menu',
      small ? 'dropdown-menu_small' : '',
      'slds-combobox_container',
      'slds-size_small',
      className || '',
    ].join(' ')
  }

  toggleDropdown() {
    this.setState({ expanded: !this.state.expanded })
  }

  onItemClick(title: string) {
    this.props.onChange(title)
    this.setState({ value: title, expanded: false })
  }

  handleClickOutside(evt) {
    if (this.state.expanded) {
      this.setState({ expanded: false })
    }
  }

  render() {
    const { children } = this.props
    const { expanded, value } = this.state
    return (
      (<div className={this.className}>
        <div
          className={`slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click
            ${expanded ? 'slds-is-open' : ''}`}
          aria-expanded={expanded}
          aria-haspopup="listbox"
          role="combobox"
        >
          <div
            className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right"
            role="none"
            onClick={this.toggleDropdown}
          >
            <input
              className="slds-input slds-combobox__input"
              aria-controls="listbox-unique-id"
              role="textbox"
              type="text"
              placeholder="Select an Option"
              value={value}
              readOnly
            />
            <SFDCIcon
              withContainer
              name="down"
              type="utility"
              size="x-small"
              textStyle="default"
              className="slds-input__icon slds-input__icon_right"
            />
          </div>
          <div id="listbox-unique-id" role="listbox">
            <ul className="slds-listbox slds-listbox_vertical slds-dropdown slds-dropdown_fluid" role="presentation">
              {children.map(cmp => React.cloneElement(cmp, {
                selected: value && cmp.props.title === String(value),
                onClick: this.onItemClick.bind(this, cmp.props.title),
              }))}
            </ul>
          </div>
        </div>
      </div>)
    )
  }
}

export const DropdownMenu = onClickOutside(BaseDropdownMenu)
