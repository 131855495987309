import * as React from 'react'
import { Button } from '@components/Button'

interface Props {
  value: number
  fillRatio: number
  onClick: (value: number) => void
  isHovered: boolean
  className?: string
  onMouseOver?: (value: number) => void
  onMouseOut?: (value: number) => void
}

export class RatingStar extends React.Component<Props, any> {
  constructor(props) {
    super(props)
  }

  get fillClass() {
    const { fillRatio } = this.props
    const rounded = Math.round(fillRatio * 4) / 4
    const parts = rounded / 0.25

    return `fill-${parts}-4`
  }

  get className() {
    const { className, isHovered } = this.props
    const classes = [
      'rating-star',
      isHovered ? 'rating-star_hover' : '',
      this.fillClass,
      className || '',
    ]

    return classes.join(' ')
  }

  onMouseOver = e => {
    const { onMouseOver, value } = this.props
    if (onMouseOver) {
      onMouseOver(value)
    }
  }

  onMouseOut = e => {
    const { onMouseOut, value } = this.props
    if (onMouseOut) {
      onMouseOut(value)
    }
  }

  onClick = () => {
    this.props.onClick(this.props.value)
  }

  render() {
    const { value } = this.props
    return (
      <Button
        className={this.className}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        icon="favorite"
        type="utility"
        border-filled
        data-value={value}
        onClick={this.onClick}
      />
    )
  }
}
