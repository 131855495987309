import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { autorun, reaction } from 'mobx'
import Pagination from 'rc-pagination'
import { DataService, dataService } from '@data/data-service'
import { DropdownMenu, DropdownItem } from '@views/layout'
import { Demo, PaginationState, FilterBy, SortBy, sortingTypes,
         filterDemos, getDemosByPage } from '@data'
import { DemoListItem } from './DemoListItem'
import { DemoFilters } from './DemoFilters'

import './DemoList.scss'

interface Props {
  currentPage: number
  history: any
  pageTitle?: string
  store: DataService
}

interface State {
  sortBy: SortBy
  pageSize: number
}

export const DemoList = withRouter(inject('store')(observer(class BaseDemoList extends React.Component<any, State> {
  sortedDemos = []
  constructor(props) {
    super(props)
    const sortBy = dataService.getSortBy()
    this.state = {
      sortBy,
      pageSize: 10,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentPage, store } = this.props
    const { pageSize } = this.state
    if (pageSize * (currentPage - 1) > this.sortedDemos.length) {
      this.navigateToPage(Math.floor(this.sortedDemos.length / pageSize) + 1)
    }
  }

  getPaginationState() {
    const { currentPage } = this.props
    const { pageSize } = this.state
    return {
      current: currentPage,
      total: this.sortedDemos.length,
      pageSize,
    }
  }

  navigateToPage = (pageNum: number) => {
    const history = this.props.history
    const params = this.props.match.params
    let route = '/demos/'
    if (params.category) {
      route += `c/${encodeURIComponent(params.category)}/`
    }
    if (params.tag) {
      route += `t/${params.tag}/${encodeURIComponent(params.tagName)}/`
    }
    route += pageNum
    history.push(route)
  }

  navigateToDemo = demo => {
    const history = this.props.history
    history.push(`/demo/${demo.slug}`)
  }

  getSortedDemos = (sortBy?: SortBy, filterBy?: FilterBy) => {
    sortBy = sortBy || this.state.sortBy
    filterBy = filterBy || this.props.store.filterBy
    let demos = filterDemos(this.props.store.demos, filterBy).slice()
    if (!filterBy.keywords) {
      demos = demos.sort(sortBy.comparator)
    }
    return demos
  }

  sort = (sortBy: SortBy) => {
    this.props.store.setSortBy(sortBy)
    this.setState({
      sortBy,
    })
  }

  onPageSizeChange = (pageSize: string | number) => {
    const { currentPage } = this.props
    pageSize = +pageSize
    this.setState({ pageSize })
  }

  hasFilteredDemos = () => {
    return this.props.store.demos.length > this.sortedDemos.length
  }

  renderDisplayedDemoCount = () => {
    let message = 'No results found'
    const count = this.sortedDemos.length
    if (count === 1) {
      message = '1 result'
    } else if (count > 1) {
      message = `${count} results`
    }
    return <p className="demo-list__results-msg">{message}</p>
  }

  render() {
    const { currentPage, pageTitle, store } = this.props
    const { sortBy } = this.state
    this.sortedDemos = this.getSortedDemos()
    const pagination = this.getPaginationState()
    const displayedDemos = getDemosByPage(this.sortedDemos, pagination.pageSize, currentPage)
    return (
      <div className="demo-list">
          {pageTitle && <h2 className="slds-text-heading_medium">{pageTitle}</h2>}
          <DemoFilters
            sortingTypes={sortingTypes}
            sortBy={sortBy}
            filterBy={store.filterBy}
            onSortByChange={this.sort}
          />
          {this.hasFilteredDemos() && this.renderDisplayedDemoCount()}
          {displayedDemos.map(demo => (
            <DemoListItem demo={demo} key={demo.id} onClick={this.navigateToDemo} />
          ))}
        <div className="demo-list__pagination slds-m-top_small">
          <div className="demo-list__pagination__dropdown">
            <span>Results per page:</span>
            <DropdownMenu
              small
              className="demo-list__pagination__dropdown"
              defaultSelected={String(pagination.pageSize)}
              onChange={this.onPageSizeChange}
            >
              <DropdownItem title="5" key="5" />
              <DropdownItem title="10" key="10" />
              <DropdownItem title="15" key="15" />
              <DropdownItem title="20" key="20" />
            </DropdownMenu>
          </div>
          <Pagination
            {...pagination}
            showLessItems={true}
            className="demo-list__pagination slds-m-vertical_medium"
            onChange={this.navigateToPage}
          />
        </div>
      </div>
    )
  }
})))
